var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Checkbox, RadioGroup } from '@gravity-ui/uikit';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import OnboardingAPI from '../../api/request/onboarding/api';
export default function OnboardingForm() {
    var _a = useState(false), answered = _a[0], setAnswered = _a[1];
    var _b = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]), answers = _b[0], setAnswers = _b[1];
    var _c = useState(0), corrects = _c[0], setCorrects = _c[1];
    var _d = useState(0), checkBoxVal = _d[0], setCheckBoxVal = _d[1];
    var correctAnswers = [9, 2, 3, 2, 1, 3, 2, 3, 3, 3];
    var formRef = useRef(null);
    var _e = useState(false), pending = _e[0], setPending = _e[1];
    var resultText = {
        0: 'Попробуйте еще раз',
        1: 'Попробуйте еще раз',
        2: 'Попробуйте еще раз',
        3: 'Попробуйте еще раз',
        4: 'Попробуйте еще раз',
        5: 'Неплохо',
        6: 'Неплохо',
        7: 'Хорошо',
        8: 'Хорошо',
        9: 'Супер!',
        10: 'Супер!',
    };
    function SetAnswer(i, val) {
        var ans = __spreadArray([], answers, true);
        ans[i] = val;
        setAnswers(ans);
    }
    function GetColor(ans) {
        return {
            color: answered ? (ans ? 'var(--correct)' : 'var(--incorrect)') : 'var(--default)',
        };
    }
    function Update() {
        setPending(true);
        OnboardingAPI.GetOnboardingTest()
            .then(function (res) {
            var _a;
            setAnswered(res.answered);
            setAnswers(res.ans);
            setCorrects(res.corrects);
            setCheckBoxVal((_a = res.ans[0]) !== null && _a !== void 0 ? _a : 0);
            setPending(false);
        });
    }
    useEffect(Update, []);
    function HandleSubmit(evt) {
        setPending(true);
        evt.preventDefault();
        OnboardingAPI.SetOnboardingTest(formRef.current)
            .finally(Update);
    }
    function HandleHide() {
        OnboardingAPI.HideOnboardingTest()
            .finally(Update);
    }
    return (React.createElement("form", { className: "modern onboarding-page__form", ref: formRef, onSubmit: HandleSubmit },
        React.createElement("div", { className: "onboarding-page__question" },
            React.createElement("span", { className: "onboarding-page__question-task" }, "1. \u041F\u043E \u043A\u0430\u043A\u0438\u043C \u043D\u0430\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u044F\u043C \u043C\u044B \u0438\u043C\u0435\u0435\u043C \u043F\u0440\u0430\u0432\u043E \u043E\u0441\u0443\u0449\u0435\u0441\u0442\u0432\u043B\u044F\u0442\u044C \u043E\u0431\u0440\u0430\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C\u043D\u0443\u044E \u0434\u0435\u044F\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u044C?"),
            React.createElement("div", { className: "onboarding-page__options" },
                React.createElement(Checkbox, { disabled: answered, checked: Boolean((answers[0] >> 0) % 2), onUpdate: function (checked) { var _a; return SetAnswer(0, ((_a = answers[0]) !== null && _a !== void 0 ? _a : 0) + (1 << 0) * (checked ? 1 : -1)); }, style: GetColor((correctAnswers[0] >> 0) % 2) }, "\u0414\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u043E\u0435 \u043E\u0431\u0440\u0430\u0437\u043E\u0432\u0430\u043D\u0438\u0435 \u0434\u0435\u0442\u0435\u0439 \u0438 \u0432\u0437\u0440\u043E\u0441\u043B\u044B\u0445"),
                React.createElement(Checkbox, { disabled: answered, checked: Boolean((answers[0] >> 1) % 2), onUpdate: function (checked) {
                        var _a;
                        console.log(answers, checked);
                        SetAnswer(0, ((_a = answers[0]) !== null && _a !== void 0 ? _a : 0) + (1 << 1) * (checked ? 1 : -1));
                    }, style: GetColor((correctAnswers[0] >> 1) % 2) }, "\u0421\u0440\u0435\u0434\u043D\u0435\u0435 \u043E\u0431\u0449\u0435\u0435 \u043E\u0431\u0440\u0430\u0437\u043E\u0432\u0430\u043D\u0438\u0435"),
                React.createElement(Checkbox, { disabled: answered, checked: Boolean((answers[0] >> 2) % 2), onUpdate: function (checked) { var _a; return SetAnswer(0, ((_a = answers[0]) !== null && _a !== void 0 ? _a : 0) + (1 << 2) * (checked ? 1 : -1)); }, style: GetColor((correctAnswers[0] >> 2) % 2) }, "\u041F\u043E\u0434\u0433\u043E\u0442\u043E\u0432\u043A\u0430 \u043D\u0430\u0443\u0447\u043D\u044B\u0445 \u0438 \u043D\u0430\u0443\u0447\u043D\u043E-\u043F\u0435\u0434\u0430\u0433\u043E\u0433\u0438\u0447\u0435\u0441\u043A\u0438\u0445 \u043A\u0430\u0434\u0440\u043E\u0432"),
                React.createElement(Checkbox, { disabled: answered, checked: Boolean((answers[0] >> 3) % 2), onUpdate: function (checked) { var _a; return SetAnswer(0, ((_a = answers[0]) !== null && _a !== void 0 ? _a : 0) + (1 << 3) * (checked ? 1 : -1)); }, style: GetColor((correctAnswers[0] >> 3) % 2) }, "\u0414\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u043E\u0435 \u043F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u043E\u043D\u0430\u043B\u044C\u043D\u043E\u0435 \u043E\u0431\u0440\u0430\u0437\u043E\u0432\u0430\u043D\u0438\u0435")),
            React.createElement("input", { type: "hidden", value: answers[0], name: "ans0" })),
        React.createElement("div", { className: "onboarding-page__question" },
            React.createElement("span", { className: "onboarding-page__question-task" }, "2. \u041A\u0430\u043A\u043E\u0432\u0430 \u043E\u0441\u043D\u043E\u0432\u043D\u0430\u044F \u0446\u0435\u043B\u044C \u043F\u0440\u043E\u0435\u043A\u0442\u043E\u0432 \u0410\u0441\u0441\u043E\u0446\u0438\u0430\u0446\u0438\u0438 \u043F\u043E\u0431\u0435\u0434\u0438\u0442\u0435\u043B\u0435\u0439 \u043E\u043B\u0438\u043C\u043F\u0438\u0430\u0434 (\u0410\u041F\u041E)?"),
            React.createElement("div", { className: "onboarding-page__options" },
                React.createElement(RadioGroup, { value: String(answers[1]), onUpdate: function (val) { return SetAnswer(1, +val); }, name: "ans1", disabled: answered, direction: "vertical", options: [
                        {
                            value: '1',
                            content: React.createElement("span", { style: GetColor(correctAnswers[1] === 1) }, "\u0423\u0432\u0435\u043B\u0438\u0447\u0438\u0442\u044C \u043A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u043E\u0432 \u043E\u043B\u0438\u043C\u043F\u0438\u0430\u0434"),
                        },
                        {
                            value: '2',
                            content: React.createElement("span", { style: GetColor(correctAnswers[1] === 2) }, "\u041F\u0440\u0435\u0434\u043E\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u0443\u0447\u0435\u043D\u0438\u043A\u0430\u043C \u0440\u0430\u0432\u043D\u044B\u0439 \u0434\u043E\u0441\u0442\u0443\u043F \u043A \u0434\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u043E\u043C\u0443 \u043E\u0431\u0440\u0430\u0437\u043E\u0432\u0430\u043D\u0438\u044E"),
                        },
                        {
                            value: '3',
                            content: React.createElement("span", { style: GetColor(correctAnswers[1] === 3) }, "\u0420\u0430\u0437\u0440\u0430\u0431\u043E\u0442\u0430\u0442\u044C \u043D\u043E\u0432\u044B\u0435 \u043E\u0431\u0440\u0430\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0435 \u0441\u0442\u0430\u043D\u0434\u0430\u0440\u0442\u044B"),
                        },
                    ] }))),
        React.createElement("div", { className: "onboarding-page__question" },
            React.createElement("span", { className: "onboarding-page__question-task" }, "3. \u0413\u0434\u0435 \u043C\u043E\u0436\u043D\u043E \u043D\u0430\u0439\u0442\u0438 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442 \u00AB\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u043A\u043E\u0440\u043F\u043E\u0440\u0430\u0442\u0438\u0432\u043D\u043E\u0439 \u043A\u0443\u043B\u044C\u0442\u0443\u0440\u044B \u0438 \u044D\u0442\u0438\u043A\u0438\u00BB?"),
            React.createElement("div", { className: "onboarding-page__options" },
                React.createElement(RadioGroup, { value: String(answers[2]), onUpdate: function (val) { return SetAnswer(2, +val); }, name: "ans2", disabled: answered, direction: "vertical", options: [
                        {
                            value: '1',
                            content: React.createElement("span", { style: GetColor(correctAnswers[2] === 1) }, "\u041D\u0430 \u0441\u0430\u0439\u0442\u0435 \u0430\u043F\u043E.\u0440\u0444"),
                        },
                        {
                            value: '2',
                            content: React.createElement("span", { style: GetColor(correctAnswers[2] === 2) }, "\u0412 \u0433\u0440\u0443\u043F\u043F\u0435 \u00AB\u0410\u0441\u0441\u043E\u0446\u0438\u0430\u0446\u0438\u044F \u043F\u043E\u0431\u0435\u0434\u0438\u0442\u0435\u043B\u0435\u0439 \u043E\u043B\u0438\u043C\u043F\u0438\u0430\u0434\u00BB \u0412\u041A"),
                        },
                        {
                            value: '3',
                            content: React.createElement("span", { style: GetColor(correctAnswers[2] === 3) }, "\u0412 \u0440\u0430\u0437\u0434\u0435\u043B\u0435 \u00AB\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u0440\u0430\u0431\u043E\u0442\u044B\u00BB \u043D\u0430 HR-\u0441\u0435\u0440\u0432\u0438\u0441\u0435 \u0440\u0430\u0431\u043E\u0442\u0430.\u0430\u043F\u043E.\u0440\u0444"),
                        },
                    ] }))),
        React.createElement("div", { className: "onboarding-page__question" },
            React.createElement("span", { className: "onboarding-page__question-task" }, "4. \u041F\u043E \u043A\u0430\u043A\u043E\u043C\u0443 \u0434\u043E\u0433\u043E\u0432\u043E\u0440\u0443 \u043F\u0440\u0435\u043F\u043E\u0434\u0430\u0432\u0430\u0442\u0435\u043B\u0438 \u043F\u043E\u043B\u0443\u0447\u0430\u044E\u0442 \u043E\u043F\u043B\u0430\u0442\u0443 \u0437\u0430 \u043F\u0440\u043E\u0432\u0435\u0434\u0451\u043D\u043D\u044B\u0435 \u0437\u0430\u043D\u044F\u0442\u0438\u044F?"),
            React.createElement("div", { className: "onboarding-page__options" },
                React.createElement(RadioGroup, { value: String(answers[3]), onUpdate: function (val) { return SetAnswer(3, +val); }, name: "ans3", disabled: answered, direction: "vertical", options: [
                        {
                            value: '1',
                            content: React.createElement("span", { style: GetColor(correctAnswers[3] === 1) }, "\u0421\u0440\u043E\u0447\u043D\u044B\u0439 \u0442\u0440\u0443\u0434\u043E\u0432\u043E\u0439 \u0434\u043E\u0433\u043E\u0432\u043E\u0440"),
                        },
                        {
                            value: '2',
                            content: React.createElement("span", { style: GetColor(correctAnswers[3] === 2) }, "\u0414\u043E\u0433\u043E\u0432\u043E\u0440 \u0433\u0440\u0430\u0436\u0434\u0430\u043D\u0441\u043A\u043E-\u043F\u0440\u0430\u0432\u043E\u0432\u043E\u0433\u043E \u0445\u0430\u0440\u0430\u043A\u0442\u0435\u0440\u0430"),
                        },
                        {
                            value: '3',
                            content: React.createElement("span", { style: GetColor(correctAnswers[3] === 3) }, "\u0411\u0435\u0441\u0441\u0440\u043E\u0447\u043D\u044B\u0439 \u0442\u0440\u0443\u0434\u043E\u0432\u043E\u0439 \u0434\u043E\u0433\u043E\u0432\u043E\u0440"),
                        },
                    ] }))),
        React.createElement("div", { className: "onboarding-page__question" },
            React.createElement("span", { className: "onboarding-page__question-task" }, "5. \u041A\u0430\u043A \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u0441\u043F\u0440\u0430\u0432\u043A\u0443 \u043E\u0431 \u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0438\u0438 \u0441\u0443\u0434\u0438\u043C\u043E\u0441\u0442\u0438?"),
            React.createElement("div", { className: "onboarding-page__options" },
                React.createElement(RadioGroup, { value: String(answers[4]), onUpdate: function (val) { return SetAnswer(4, +val); }, name: "ans4", disabled: answered, direction: "vertical", options: [
                        {
                            value: '1',
                            content: React.createElement("span", { style: GetColor(correctAnswers[4] === 1) }, "\u0417\u0430\u043A\u0430\u0437\u0430\u0442\u044C \u043D\u0430 \u0413\u043E\u0441\u0443\u0441\u043B\u0443\u0433\u0430\u0445"),
                        },
                        {
                            value: '2',
                            content: React.createElement("span", { style: GetColor(correctAnswers[4] === 2) }, "\u041F\u0440\u0438\u0439\u0442\u0438 \u0432 \u0440\u0430\u0439\u043E\u043D\u043D\u044B\u0439 \u0441\u0443\u0434"),
                        },
                    ] }))),
        React.createElement("div", { className: "onboarding-page__question" },
            React.createElement("span", { className: "onboarding-page__question-task" }, "6. \u041A\u0442\u043E \u044F\u0432\u043B\u044F\u0435\u0442\u0441\u044F \u043D\u0435\u043F\u043E\u0441\u0440\u0435\u0434\u0441\u0442\u0432\u0435\u043D\u043D\u044B\u043C \u0440\u0443\u043A\u043E\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u0435\u043C \u043F\u0440\u0435\u043F\u043E\u0434\u0430\u0432\u0430\u0442\u0435\u043B\u044F \u043D\u0430 \u043A\u0430\u0444\u0435\u0434\u0440\u0435?"),
            React.createElement("div", { className: "onboarding-page__options" },
                React.createElement(RadioGroup, { value: String(answers[5]), onUpdate: function (val) { return SetAnswer(5, +val); }, name: "ans5", disabled: answered, direction: "vertical", options: [
                        {
                            value: '1',
                            content: React.createElement("span", { style: GetColor(correctAnswers[5] === 1) }, "\u0420\u0443\u043A\u043E\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u0438 \u0443\u0447\u0435\u0431\u043D\u043E-\u043C\u0435\u0442\u043E\u0434\u0438\u0447\u0435\u0441\u043A\u043E\u0433\u043E \u043E\u0442\u0434\u0435\u043B\u0430 (\u0410\u043D\u043D\u0430 \u0421\u0451\u043C\u043E\u0447\u043A\u0438\u043D\u0430 \u0438 \u0420\u043E\u043C\u0430\u043D \u0418\u043B\u044E\u0448\u0438\u043D)"),
                        },
                        {
                            value: '2',
                            content: React.createElement("span", { style: GetColor(correctAnswers[5] === 2) }, "\u0420\u0443\u043A\u043E\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u044C \u0410\u041F\u041E (\u0413\u043B\u0435\u0431 \u0417\u0443\u0435\u0432)"),
                        },
                        {
                            value: '3',
                            content: React.createElement("span", { style: GetColor(correctAnswers[5] === 3) }, "\u0417\u0430\u0432\u0435\u0434\u0443\u044E\u0449\u0438\u0439 \u0438\u043B\u0438 \u0437\u0430\u0432\u0435\u0434\u0443\u044E\u0449\u0430\u044F \u043A\u0430\u0444\u0435\u0434\u0440\u043E\u0439"),
                        },
                    ] }))),
        React.createElement("div", { className: "onboarding-page__question" },
            React.createElement("span", { className: "onboarding-page__question-task" }, "7. \u0412\u044B \u0441\u043E\u0433\u043B\u0430\u0448\u0430\u0435\u0442\u0435\u0441\u044C \u0432\u0435\u0441\u0442\u0438 \u043A\u0443\u0440\u0441 \u0423\u0422\u0421 \u0432 \u0448\u043A\u043E\u043B\u0435. \u0421\u0442\u0430\u0432\u043A\u0430, \u043A\u043E\u0442\u043E\u0440\u0443\u044E \u0432\u0430\u043C \u0441\u043E\u043E\u0431\u0449\u0438\u043B \u0437\u0430\u0432\u0435\u0434\u0443\u044E\u0449\u0438\u0435 \u0437\u0430 \u043E\u0434\u043D\u043E \u0437\u0430\u043D\u044F\u0442\u0438\u0435 \u2013 3500 \u0440\u0443\u0431\u043B\u0435\u0439. \u041A\u0430\u043A\u0443\u044E \u0441\u0443\u043C\u043C\u0443 \u0432\u044B \u043F\u043E\u043B\u0443\u0447\u0438\u0442\u0435 \u0437\u0430 \u043F\u0440\u043E\u0432\u0435\u0434\u0435\u043D\u0438\u0435 \u044D\u0442\u043E\u0433\u043E \u0437\u0430\u043D\u044F\u0442\u0438\u044F?"),
            React.createElement("div", { className: "onboarding-page__options" },
                React.createElement(RadioGroup, { value: String(answers[6]), onUpdate: function (val) { return SetAnswer(6, +val); }, name: "ans6", disabled: answered, direction: "vertical", options: [
                        {
                            value: '1',
                            content: React.createElement("span", { style: GetColor(correctAnswers[6] === 1) }, "3500 \u0440\u0443\u0431\u043B\u0435\u0439"),
                        },
                        {
                            value: '2',
                            content: React.createElement("span", { style: GetColor(correctAnswers[6] === 2) }, "3045 \u0440\u0443\u0431\u043B\u0435\u0439"),
                        },
                    ] }))),
        React.createElement("div", { className: "onboarding-page__question" },
            React.createElement("span", { className: "onboarding-page__question-task" }, "8. \u041A\u043E\u043C\u0443 \u0432 \u043F\u0435\u0440\u0432\u0443\u044E \u043E\u0447\u0435\u0440\u0435\u0434\u044C \u0441\u0442\u043E\u0438\u0442 \u0441\u043E\u043E\u0431\u0449\u0438\u0442\u044C \u043E \u0442\u043E\u043C, \u0447\u0442\u043E \u0432\u044B \u043E\u043F\u0430\u0437\u0434\u044B\u0432\u0430\u0435\u0442\u0435 \u043D\u0430 \u0437\u0430\u043D\u044F\u0442\u0438\u0435 \u0432 \u0448\u043A\u043E\u043B\u0435?"),
            React.createElement("div", { className: "onboarding-page__options" },
                React.createElement(RadioGroup, { value: String(answers[7]), onUpdate: function (val) { return SetAnswer(7, +val); }, name: "ans7", disabled: answered, direction: "vertical", options: [
                        {
                            value: '1',
                            content: React.createElement("span", { style: GetColor(correctAnswers[7] === 1) }, "\u041C\u0435\u043D\u0435\u0434\u0436\u0435\u0440\u0443 \u043A\u0430\u0444\u0435\u0434\u0440\u044B"),
                        },
                        {
                            value: '2',
                            content: React.createElement("span", { style: GetColor(correctAnswers[7] === 2) }, "\u0417\u0430\u0432\u0435\u0434\u0443\u044E\u0449\u0435\u043C\u0443 \u043A\u0430\u0444\u0435\u0434\u0440\u043E\u0439"),
                        },
                        {
                            value: '3',
                            content: React.createElement("span", { style: GetColor(correctAnswers[7] === 3) }, "\u041C\u0435\u043D\u0435\u0434\u0436\u0435\u0440\u0443 \u0441\u043B\u0443\u0436\u0431\u044B \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0438"),
                        },
                    ] }))),
        React.createElement("div", { className: "onboarding-page__question" },
            React.createElement("span", { className: "onboarding-page__question-task" }, "9. \u041A\u0430\u043A \u043D\u0430\u0437\u044B\u0432\u0430\u0435\u0442\u0441\u044F \u043D\u0430\u0443\u0447\u043D\u043E-\u043F\u043E\u043F\u0443\u043B\u044F\u0440\u043D\u044B\u0439 \u043A\u0430\u043D\u0430\u043B, \u0432 \u0440\u0430\u043C\u043A\u0430\u0445 \u043A\u043E\u0442\u043E\u0440\u043E\u0433\u043E \u043C\u043E\u0436\u043D\u043E \u0440\u0430\u0441\u0441\u043A\u0430\u0437\u0430\u0442\u044C \u043E \u0442\u0435\u043C\u0435, \u043A\u043E\u0442\u043E\u0440\u0430\u044F \u0432\u0430\u0441 \u0438\u043D\u0442\u0435\u0440\u0435\u0441\u0443\u0435\u0442, \u043D\u043E \u043D\u0430 \u043A\u043E\u0442\u043E\u0440\u0443\u044E \u043D\u0435 \u0445\u0432\u0430\u0442\u0430\u0435\u0442 \u0432\u0440\u0435\u043C\u0435\u043D\u0438 \u043D\u0430 \u0437\u0430\u043D\u044F\u0442\u0438\u044F\u0445?"),
            React.createElement("div", { className: "onboarding-page__options" },
                React.createElement(RadioGroup, { value: String(answers[8]), onUpdate: function (val) { return SetAnswer(8, +val); }, name: "ans8", disabled: answered, direction: "vertical", options: [
                        {
                            value: '1',
                            content: React.createElement("span", { style: GetColor(correctAnswers[8] === 1) }, "\u041A\u0430\u0440\u0430\u043D\u0434\u0430\u0448"),
                        },
                        {
                            value: '2',
                            content: React.createElement("span", { style: GetColor(correctAnswers[8] === 2) }, "\u0411\u043B\u043E\u043A\u043D\u043E\u0442"),
                        },
                        {
                            value: '3',
                            content: React.createElement("span", { style: GetColor(correctAnswers[8] === 3) }, "\u041C\u0430\u0440\u043A\u0435\u0440"),
                        },
                    ] }))),
        React.createElement("div", { className: "onboarding-page__question" },
            React.createElement("span", { className: "onboarding-page__question-task" }, "10. \u0413\u0434\u0435 \u043C\u043E\u0436\u043D\u043E \u0443\u0437\u043D\u0430\u0442\u044C \u043E \u043C\u0435\u0440\u043E\u043F\u0440\u0438\u044F\u0442\u0438\u044F\u0445 \u0438 \u0434\u0440\u0443\u0433\u0438\u0445 \u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E\u0441\u0442\u044F\u0445 \u0434\u043B\u044F \u043F\u0440\u0435\u043F\u043E\u0434\u0430\u0432\u0430\u0442\u0435\u043B\u0435\u0439 \u0410\u041F\u041E?"),
            React.createElement("div", { className: "onboarding-page__options" },
                React.createElement(RadioGroup, { value: String(answers[9]), onUpdate: function (val) { return SetAnswer(9, +val); }, name: "ans9", disabled: answered, direction: "vertical", options: [
                        {
                            value: '1',
                            content: React.createElement("span", { style: GetColor(correctAnswers[9] === 1) }, "\u041D\u0430 \u0441\u0430\u0439\u0442\u0435 \u0430\u043F\u043E.\u0440\u0444"),
                        },
                        {
                            value: '2',
                            content: React.createElement("span", { style: GetColor(correctAnswers[9] === 2) }, "\u041D\u0430 HR-\u0441\u0435\u0440\u0432\u0438\u0441\u0435 \u0440\u0430\u0431\u043E\u0442\u0430.\u0430\u043F\u043E.\u0440\u0444"),
                        },
                        {
                            value: '3',
                            content: React.createElement("span", { style: GetColor(correctAnswers[9] === 3) }, "\u0412 \u0433\u0440\u0443\u043F\u043F\u0435 \u00AB\u0423\u0447\u0438\u0442\u0435\u043B\u044C\u0441\u043A\u0430\u044F \u0410\u041F\u041E\u00BB"),
                        },
                    ] }))),
        React.createElement("div", { className: "onboarding-page__result", hidden: !answered },
            corrects,
            "/",
            answers.length,
            " ",
            React.createElement("span", { className: "onboarding-page__result-text" }, resultText[corrects])),
        answered ?
            React.createElement("button", { type: "reset", className: "form-button onboarding-page__submit-button", onClick: HandleHide }, "\u041F\u0440\u043E\u0439\u0442\u0438 \u0435\u0449\u0435 \u0440\u0430\u0437") :
            React.createElement("button", { className: "form-button onboarding-page__submit-button", disabled: answered }, "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C")));
}
